<template>
  <div class="flex">
      <div class="col-one">
        <h3><span>Somos o melhor lugar para você vender </span>seu carro rápido.</h3>
        <h5>Anuncie seu veículo para muitas pessoas pra fechar o melhor negócio. Não deixa pra depois!</h5>
        <div class="btn-venda">
            <span>Vender meu carro</span>
        </div><!--btn-venda-->
      </div><!--col-one-->
      <div class="col-two">
        <img src="../assets/img-box.jpg" alt="">
      </div><!--col-two-->
  </div><!--flex-->
</template>

<script>
export default {

}
</script>

<style scoped>
    .flex{
        margin-top: 60px;
        text-align: left;
    }
    div.col-one{
        width: 48%;
    }

    div.col-one h3{
        font-size: 34px;
        font-family: 'Inter', sans-serif;
        font-weight: 900;
        color: var(--color-pp--);
        margin-bottom: 20px;
    }

    div.col-one h3 span{
        color: var(--color-white--);
        font-weight: 600;
    }

    div.col-one h5{
        font-size: 18px;
        font-family: 'Inter', sans-serif;
        font-weight: 300;
        color: var(--color-white--);
        margin-bottom: 30px;
    }

    div.col-one div.btn-venda{
        width: 100%;
        padding: 15px 20px;
        background: var(--color-pp--);
        border-radius: 8px;
        text-align: center;
        transition: .5s;
        cursor: pointer;
    }

    div.col-one div.btn-venda:hover{
        background: var(--color-pp-hover--);
    }

     div.col-one div.btn-venda span{
        color: var(--color-white--);
        font-size: 20px;
        font-family: 'Inter', sans-serif;
        font-weight: 900;
     }

     div.col-two{
        width: 48%;
        height: auto;
     }

     div.col-two img{
        width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 20px;
     }

     @media screen and (max-width: 800px) {
        div.col-one{
            width: 100%;
            margin-bottom: 30px;
            text-align: center;
        }
        div.col-two{
            width: 100%;
            height: auto;
        }
     }

     @media screen and (max-width: 645px){
        .flex{
        margin-top: 40px;
    }
        div.col-one h3{
            font-size: 24px;
        }
        div.col-one h5{
            font-size: 18px;
        }
        div.col-one div.btn-venda span{
            font-size: 16px;
        }

     }
</style>