<template>
  <div class="form-contato" v-if="mostrar">
    <form id="form-initial" @submit="enviarForm">
        <div class="input">
            <label for="nome">Seu nome completo</label>
            <input type="text" v-model="nome" required placeholder="Digite seu nome" id="nome">
        </div><!--input-->

        <div class="input">
            <label for="email">Seu email</label>
            <input type="email" v-model="email" required placeholder="Digite seu email" id="email">
        </div><!--input-->

        <div class="input">
            <label for="telefone">Seu telefone</label>
            <input type="tel" v-model="telefone" required placeholder="Digite seu telefone" id="telefone">
        </div><!--input-->

        <div class="submit">
            <div class="load" v-if="esconderLoad"></div>
            <input type="submit" value="Enviar Dados" id="submit-initial" v-if="!esconderSubmit">
        </div><!--submti-->
    </form><!--form-initial-->
  </div><!--for-contato-->
</template>

<script>
export default {
    props: {
        mostrar: Boolean
    },
    data(){
        return {
            esconderLoad: false,
            nome: '',
            email: '',
            telefone: '',
            esconderSubmit: false
        }
    },
    methods: {
        enviarForm(event){
            event.preventDefault();
            //Enviar formulario

            this.esconderLoad = true;
            this.esconderSubmit = true;
        }
    }
}
</script>

<style scoped>
    div.form-contato{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.336);
        z-index: 10000;
        display: flex;
        align-items: center;
        justify-content: center;
        animation-name: iniciar;
        animation-duration: .5s;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    @keyframes iniciar {
        from{
            opacity: 0;
        }
        to{
            opacity: 1;
        }
    }

    div.form-contato > form{
        width: 90%;
        max-width: 800px;
        padding: 30px 30px;
        background: var(--color-white--);
        border-radius: 8px;
        margin: 0 auto;
    }

    .input{
        margin-bottom: 20px
    }

    .input label{
        color: var(--color-black--);
        font-size: 18px;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
        display: block;
        margin-bottom: 10px;
    }

    .input input{
        width: 100%;
        padding: 10px 8px;
        border-radius: 5px;
        border: 1px solid rgb(197, 197, 197);
        transition: .5s;
        font-size: 14px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }

    .input input:focus{
        border: 1px solid var(--color-black--);
        outline: none;
    }

    .submit{
        width: 100%;
        height: 48px;
        background: var(--color-pp--);
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .submit .load{
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 4px solid rgba(255, 255, 255, 0.404);
        border-top-color: white;
        animation-name: loading;
        animation-duration: .5s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    @keyframes loading {
        from{
            transform: rotate(0deg);
        }to{
            transform: rotate(360deg);
        }
    }

    .submit input{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: var(--color-white--);
        cursor: pointer;
    }
    
</style>