<template>
    <div class="flex">
        <div class="col-one">
            <h2>Realize seus <span>Sonhos</span>
            com <span>Cartas Contempladas</span></h2>
            <ul>
                <li>+ Poder de Compra</li>
                <li>+ Crédito Imediato com os Benefícios de Consórcios</li>
                <li>+ Segurança na Negociação</li>
            </ul>

            <a href="https://cartaconteplada.franquiasdobrasil.com/" class="btn-action-cartas" id="label-carta">
                <p>Ver Cartas Disponíveis</p>
            </a><!--btn-action-->
        </div><!--col-one-->

        <div class="col-two">
            <div class="imagem">
                <img src="../assets/img-cartas.png" alt="">
            </div><!--imagem-->
        </div><!--col-two-->
    </div><!--flex-->
</template>

<script>
export default {

}
</script>


<style scoped>
div.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 60px;
}
.flex div.col-one{
    width: 50%;
}

.flex div.col-one h2{
    font-size: 40px;
    width: 80%;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: var(--color-black--);
    margin-bottom: 30px;
}

.flex div.col-one h2 span{
    color: var(--color-pp--);
}

.flex div.col-one ul{
    list-style-type: none;
    margin-bottom: 30px;
}

.flex div.col-one ul li{
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    color: var(--color-black--);
    margin-bottom: 20px;
    font-weight: 400;
}

.flex div.col-one ul li:last-child{
    margin-bottom: 0px;
}

.flex div.col-one .btn-action-cartas{
    padding: 15px 10px;
    text-align: center;
    border-radius: 10px;
    background: var(--color-pp--);
    width: 85%;
    display: inline-block;
    text-decoration: none;
    color: var(--color-white--);
    transition: .5s;
}

.flex div.col-one .btn-action-cartas:hover{
    background: var(--color-pp-hover--);
}

.flex div.col-one .btn-action-cartas p{
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-weight: 600;
}

.flex div.col-two{
    width: 50%;
    height: 482px;
    text-align: right;
}


.flex div.col-two .imagem{
    width: 100%;
    max-width: 626px;
    display: inline-block;
    height: 100%;
}

.flex div.col-two .imagem img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media screen and (max-width: 1380px) {
    .flex div.col-one h2{
        font-size: 36px;
        width: 100%;
    }
    .flex div.col-two .imagem{
        max-width: 525px;
    }
}

@media screen and (max-width: 1030px){
    .flex{
        flex-wrap: wrap;
    }

    .flex div.col-one{
        width: 100%;
        order: 2;
        text-align: center;
    }

    .flex div.col-one h2{
        width: 100%;
    }

    .flex div.col-one .btn-action-cartas{
        margin: 0 auto;
        width: 100%;
        max-width: 600px;
    }

    .flex div.col-two{
        width: 100%;
        order: 1;
        text-align: center;
        height: 500px;
        margin-bottom:30px;
    }

    .flex div.col-two .imagem{
        max-width: none !important;
        text-align: center;
        margin: 0 auto;
    }
    
    .flex div.col-two .imagem img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

@media screen and (max-width: 600px){
    div.flex{
    margin-bottom: 40px;
}
    .flex div.col-one h2{
        font-size: 28px;
        margin-bottom: 10px;
    }
    
    .flex div.col-one ul{
        margin-bottom: 20px;
    }
    
    .flex div.col-one ul li{
        font-size: 18px;
        margin-bottom: 10px;
    }

    .flex div.col-one .btn-action-cartas{
        padding: 10px 10px;
    }
    .flex div.col-one .btn-action-cartas p{
        font-size: 18px;
    }
    .flex div.col-two{
        height: 350px;
        margin-bottom: 20px;
    }
    
}

@media screen and (max-width: 450px){
    .flex div.col-one h2{
        font-size: 22px;
        margin-bottom: 10px;
    }
    
    .flex div.col-one ul{
        margin-bottom: 15px;
    }
    
    .flex div.col-one ul li{
        font-size: 14px;
        margin-bottom: 8px;
    }

    .flex div.col-one .btn-action-cartas{
        padding: 10px 10px;
    }
    .flex div.col-one .btn-action-cartas p{
        font-size: 14px;
    }
    .flex div.col-two{
        height: 250px;
        margin-bottom: 15px;
    }
    
}
</style>