<template>
  <router-view/>
</template>

<style>
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box
}
  @import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

  :root{
    --color-white--: white;
    --color-white-hover--: rgb(211, 211, 211);
    --color-black--: black;
    --color-pp--: #FF7A00;
    --color-pp-hover--: #DD6A00;
    --color-dark--: #252525;
  }

  /**Framework */
  div.container{
    width: 100%;
    max-width: 1240px;
    padding: 0 3%;
    margin: 0 auto;
  }
  /**Framework */
</style>
