<template>
  <section class="sessao-one">
    <div class="container">
      <HeaderLinks/>
      <InfoPrimaria @scroll-one="scrollOne"/>
    </div><!--container-->
  </section><!--sessao-one-->

  <section class="sessao-two">
    <div class="container">
      <NossosServicos ref="informacaoSecundaria"/>
    </div><!--container-->
  </section><!--sessao-two-->
  
  <section class="sessao-three">
    <div class="container">
      <InfoTerceira @scroll-two="scrollTwo" @scroll-three="scrollThree"/>
    </div><!--container-->
    <div class="overflow-hidden">
      <div class="bg-icon"></div>
    </div><!--overflow-hidden-->
  </section><!--sessao-three-->

  <section class="sessao-four">
    <div class="container">
      <FormContato ref="informacaoTerceira"/>
      <CtaVenderCarro/>
    </div><!--container-->
  </section><!--sessao-four-->

  <section class="sessao-five">
    <div class="container">
      <CtaCartas/>
      <SobreEmpresa ref="sobreEmpresa"/>
    </div><!--container-->
  </section><!--sessao-five-->
  
  <section class="sessao-six">
    <div class="container">
      <InfoQuarta/>
      <FooterMain/>
    </div><!--container-->
  </section><!--sessao-six-->
</template>

<script>
import HeaderLinks from '@/components/HeaderLinks.vue';
import InfoPrimaria from '@/components/InfoPrimaria.vue';
import NossosServicos from '@/components/NossosServicos.vue';
import InfoTerceira from '@/components/InfoTerceira.vue';
import FormContato from '@/components/FormContato.vue';
import CtaVenderCarro from '@/components/CtaVenderCarro.vue';
import CtaCartas from '@/components/CtaCartas.vue';
import SobreEmpresa from '@/components/SobreEmpresa.vue';
import InfoQuarta from '@/components/InformacaoQuarta.vue';
import FooterMain from '@/components/MainFooter.vue';
export default {
  components: {
    HeaderLinks,
    InfoPrimaria,
    NossosServicos,
    InfoTerceira,
    FormContato,
    CtaVenderCarro,
    CtaCartas,
    SobreEmpresa,
    InfoQuarta,
    FooterMain
  },
  methods: {
    scrollOne(){
      this.$refs.informacaoSecundaria.scrollToSection();
    },
    scrollTwo(){
      this.$refs.informacaoTerceira.scrollToSection();
    },
    scrollThree(){
      this.$refs.sobreEmpresa.scrollToSection();
    }
  }
}
</script>

<style scoped>
  section.sessao-one{
    width: 100%;
    padding: 30px 0px;
    background: var(--color-black--);
    text-align: center;
  }
  
  section.sessao-two{
    width: 100%;
    padding: 50px 0px;
    background: var(--color-white--);
    margin-bottom: 20px;
  }

  section.sessao-three{
    width: 100%;
    background: var(--color-white--);
    position: relative;
    overflow: hidden;
    padding-bottom: 100px;
  }

  section.sessao-three .overflow-hidden{
    width: 100%;
  }

  section.sessao-three .bg-icon{
    width: 110%;
    height: 300px;
    background: var(--color-black--);
    position: absolute;
    bottom: -220px;
    left: 0;
    rotate: 354deg;
    z-index: 0;
  }

    section.sessao-four{
      width: 100%;
      background: var(--color-black--);
      padding: 40px 0px;
      position: relative;
      overflow: hidden;
      padding-bottom: 180px;
    }
  
  section.sessao-four::after{
    content: '';
    position: absolute;
    bottom: -120px;
    left: 0;
    width: 110%;
    height: 200px;
    background: var(--color-white--);
    z-index: 0;
    rotate: 354deg;
  }

  section.sessao-five{
    width: 100%;
    padding: 0px 0px;
    padding-bottom: 90px;
    position: relative;
    overflow: hidden;
  }

  section.sessao-five::after{
    content: '';
    position: absolute;
    bottom: -120px;
    left: 0;
    width: 110%;
    height: 200px;
    background: var(--color-black--);
    z-index: 0;
    rotate: 354deg;
  }

  section.sessao-six{
    width: 100%;
    height: auto;
    padding: 40px 0px;
    background: var(--color-black--);
  }

  @media screen and (max-width: 825px) {
    section.sessao-three{
      padding-bottom: 80px;
    }
  }
  @media screen and (max-width: 750px) {
      section.sessao-two{
      padding: 30px 0px;
    }
  }

  @media screen and (max-width:750px) {
    section.sessao-four{
      width: 100%;
      background: var(--color-black--);
      padding: 40px 0px;
      position: relative;
      overflow: hidden;
      padding-bottom: 140px;
    }
  }

  @media screen and (max-width: 970px){
    
  section.sessao-five{
    width: 100%;
    padding: 0px 0px;
    padding-bottom: 110px;
    position: relative;
    overflow: hidden;
  }
    section.sessao-five::after{
      bottom: -140px;
    }
  }
</style>