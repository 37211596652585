<template>
<FormularioCadastro :mostrar="mostrarBox"/>
  <div class="formulario-cadastro" ref="formContato">
    <h2>Nossos planos de <span>ASSESSORIA</span> e <span>CONSULTORIA</span></h2>

    <div class="flex">
        <div class="col-one">
            <h2>Assessoria Plus anual GOLD</h2>
            <h4>Serviços Incluídos</h4>
            <ul>
                <li>
                    Prazo: 6 meses
                </li> 
                <li>
                    Quantidade de estudos para contratação de administradora de consórcio: 1
                </li> 
                <li>
                    Limite de cotas intermediadas: 1
                </li> 
                <li>
                    Quantidade de estudos para oferta de lances em assembleia: 2
                </li> 
                <li>
                    Quantidade de assembleias para participar: 2 + R$ 100,00 por cada assembleia
                        excedente.
                </li> 
                <li>
                    Limite de intermediação em compra e venda de veículo, incluindo anúncios e
                    lojas parceiras: 3% de comissão em cada veículo adquirido/vendido.

                </li> 
                <li>
                    Limite na intermediação de compra e venda de cota contemplada: 5% de
                        comissão em cada cota contemplada adquirida/vendido.

                </li> 
                    <li>
                    Valor cobrado para anunciar a venda da cota contemplada: R$ 500,00
                </li> 
                <li>
                    Valor cobrado sobre cada contemplação: R$ 1.000,00
                </li> 
                <li>Bonus: <span style="background: white; padding: 3px 5px; color: var(--color-pp--); font-weight:600;">E-book manual de cuidados com o carro</span>
                </li>
            </ul>

            <div class="valores">
                <h3>R$2.500,00</h3>
                <p>à vista</p>
            </div><!--valores-->

            <div class="btn-action" @click="exibirBox">
                <span>Quero esse Plano</span>
            </div><!--btn-action-->
        </div><!--col-one-->

        <div class="col-two">
            <h2>Assessoria Plus anual DIAMOND</h2>
            <h4>Serviços Incluídos</h4>
            <ul class="orange">
                <li>
                    Prazo: <span style="text-decoration: underline;">2 anos</span>
                </li> 
                <li>
                    Quantidade de análise e estudo para contratação de administradora de consórcio: <span style="text-decoration: underline;">até 5</span>
                </li> 
                <li>
                    Limite de cotas intermediadas: <span style="text-decoration: underline;">até 12</span>
                </li> 
                <li>
                    Quantidade de assembleias para participar: <span style="text-decoration: underline;">até 24</span>
                </li> 
                <li>
                    Limite de intermediação em compra e venda de veículo, incluindo anúncios e lojas parceiras: <span style="text-decoration: underline;">até 5</span>
                </li> 
                <li>
                    Limite de intermediação em compra e venda de cota contemplada: <span style="text-decoration: underline;">até 5</span>
                </li> 
                <li>
                    Valor cobrado sobre a venda da cota contemplada no site <span style="background:var(--color-pp--); padding: 3px 5px; color: white; font-weight:600;">R$500,00</span>:  <span style="text-decoration: underline;">ISENTO</span>
                </li> 
                    <li>
                    Valor cobrado para anunciar a venda da cota contemplada no site <span style="background:var(--color-pp--); padding: 3px 5px; color: white; font-weight:600;">R$500,00</span>:  <span style="text-decoration: underline;">ISENTO</span>
                </li> 
                <li>
                    Percentual cobrado sobre o valor do crédito/bem adquirido ou vendido: <span style="text-decoration: underline;">1%</span>
                </li> 
                <li>
                    Valor cobrado sobre cada contemplação: <span style="text-decoration: underline;">R$800,00</span>
                </li> 
                <li>Bonus: <span style="background: var(--color-pp--); padding: 3px 5px; color: white; font-weight:600;">E-book manual de cuidados com o carro</span>
                </li>
            </ul>

            <div class="lista-two">
                <h2>Brindes</h2>
                <ul>
                    <li>Ajuda na escolha do automóvel</li>
                    <li>Levamos o automóvel para a vistoria cautelar DEKRA</li>
                    <li>Organização dos documentos do veículo para fazer a alienação</li>
                    <li>Exigimos do vendedor do carro a garantia de 90 dias no contrato</li>
                    <li>Realizamos um laudo do veículo para verificar se foi de leilão ou teve passagem por algo importante</li>
                    <li>Entregamos o carro limpo, polido e higienizado</li>
                    <li>E-BOOK de como cuidar do carro</li>
                    <li>E-BOOK de como ser contemplado mais rápido</li>
                </ul>
                <p>Venha aproveitar todos esses benefícios e tenha uma experiência tranquila e segura na aquisição do seu veículo!</p>
            </div><!--lista-two-->

            <div class="btn-action orange" @click="exibirBox">
                <span>Quero esse Plano</span>
            </div><!--btn-action-->
        </div><!--col-two-->
    </div><!--flex-->
  </div><!--formulario cadastro-->
</template>

<script>
import FormularioCadastro from './FormularioCadastro.vue';
export default {
    components: {
        FormularioCadastro
    },
    data(){
        return {
            mostrarBox: false
        }
    },
    methods: {
        exibirBox(){
            this.mostrarBox = true;
        },
        scrollToSection(){
            this.$refs.formContato.scrollIntoView({ behavior: 'smooth' }); 
        }
    }
}
</script>

<style scoped>
    div.formulario-cadastro{
        width: 100%;
        text-align: center;
        position: relative;
        z-index: 1;
    }

    div.formulario-cadastro h2{
        color: var(--color-white--);
        font-size: 29px;
        font-weight: 600;
        font-family: 'Roboto', sans-serif;
        margin-bottom: 30px;
    }

    div.formulario-cadastro h2 span{
        color: var(--color-pp--);
    }

    .flex{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .flex .col-one{
        width: calc(100% / 2 - 15px);
        background: var(--color-pp--);
        border-radius: 15px;
        overflow: hidden;
        text-align: center;
        padding: 25px 20px;
    }

    .flex .col-one h2{
        font-size: 26px;
        color: white;
        margin-bottom: 20px;
        font-family: 'Roboto';
        font-weight: 800;
    }
    
    .flex .col-one h4{
        font-size: 22px;
        font-family: 'Roboto';
        color: white;
        text-align: center;
        margin-bottom: 30px;
        font-weight: 600;
    }

    .flex .col-two{
         width: calc(100% / 2 - 15px);
        background: var(--color-white--);
        border-radius: 15px;
        overflow: hidden;
        text-align: center;
        padding: 25px 20px;
    }

    .flex .col-two h2{
        font-size: 26px;
        color: var(--color-pp--);
        margin-bottom: 20px;
        font-family: 'Roboto';
        font-weight: 800;
    }
    
    .flex .col-two h4{
        font-size: 22px;
        font-family: 'Roboto';
        color: var(--color-pp--);
        text-align: center;
        margin-bottom: 30px;
        font-weight: 600;
    }

    ul{
        display: inline-block;
        list-style-type: disc;
        text-align: left;
        padding: 0px 40px;
    }

    ul li{
        font-size: 18px;
        font-family: 'Roboto';
        text-align: left;
        font-weight: 500;
        margin-bottom: 15px;
        color: var(--color-white--);
    }

    ul.orange{
        margin-bottom: 30px;
    }

    ul.orange li{
        color: var(--color-pp--);
    }

    div.valores{
        margin-top: 10px;
        text-align: center;
        margin-bottom: 20px;
    }
    
    div.valores h3{
        color: var(--color-white--);
        font-size: 38px;
        color: white;
        font-family: 'Roboto';
        font-weight: 900;
    }

    div.valores p{
        color: var(--color-white--);
        position: relative;
        top: 1px;
        font-weight: 500;
        font-family: 'Roboto';
        margin-bottom: 0px;
        font-size: 24px;
    }

    div.btn-action{
        width: 100%;
        padding: 15px 20px;
        border-radius: 8px;
        background: var(--color-white--);
        transition: .5s;
        cursor: pointer;
        transition: .5s;
    }

    div.btn-action.orange{
        background: var(--color-pp--);
    }

    div.btn-action:hover{
        background: var(--color-black--);
    }

    div.btn-action span{
        font-size: 22px;
        color: var(--color-pp--);
        font-family: 'Roboto';
        font-weight: 800;
        margin: 0px;
    }

    div.btn-action.orange span{
        color: var(--color-white--);
    }

    .lista-two{
        margin-bottom: 30px;
    }

    .lista-two > h3{
        font-size: 22px;
        font-family: 'Roboto';
        color: var(--color-pp--);
        text-align: center;
        margin-bottom: 30px;
        font-weight: 600;
    }

    .lista-two ul li{
        color: var(--color-pp--);
    }

    .lista-two > p{
        color: var(--color-pp--);
        font-size: 18px;
        font-family: 'Roboto';
        font-weight: 700;
        text-decoration: underline;
        margin-top: 15px;
        text-align: center;
    }

    @media screen and (max-width: 870px) {
        .flex .col-one{
            width: 100% !important;
            margin-bottom: 30px;
        }

        .flex .col-two{
            width: 100% !important;
        }
        div.formulario-cadastro h2{
            font-size: 24px;
        }

        div.formulario-cadastro h2{
            font-size: 24px;
        }
        .flex .col-one h2{
            font-size: 24px;
             margin-bottom: 20px;
        }
        .flex .col-one h4{
            font-size: 20px;
             margin-bottom: 20px;
        }
        .flex .col-two h2{
            font-size: 24px;
            margin-bottom: 20px;
        }
        .flex .col-two h4{
            font-size: 20px;
             margin-bottom: 20px;
        }
        ul{
            padding: 0px 30px;
        }

        ul li{
            font-size: 16px;
        }
        div.valores h3{
            font-size: 32px;
        }
        div.valores p{
            font-size: 22px;
        }

        div.btn-action span{
        font-size: 20px;
        }

    .lista-two > p{
            font-size: 16px;
        }

    }

    @media screen and (max-width: 490px) {
        ul{
            padding: 0px 20px;
        }
    }
</style>