<template>
    <section class="sessao-one">
    <div class="container">
      <HeaderLinksVue/>
      <VenderCarro/>
    </div><!--container-->
  </section><!--sessao-one-->

    <section class="sessao-two">
        <div class="container">
            <MotivosCarro/>
        </div><!--container-->
    </section><!--sessao-two-->

    <section class="sessao-three">
        <div class="container">
            <FacilVender/>
        </div><!--container-->
    </section><!--sessao-four-->

    <section class="sessao-four">
        <div class="container">
            <FormVendaCarro/>
        </div><!--container-->
    </section><!--sessao-four-->
</template>

<script>
import VenderCarro from '@/components/VenderCarro.vue';
import MotivosCarro from '@/components/MotivosCarro.vue';
import FacilVender from '@/components/FacilVender.vue';
import FormVendaCarro from '@/components/FormVendaCarro.vue'
import HeaderLinksVue from '@/components/HeaderLinks.vue';
export default {
    components: {
        HeaderLinksVue,
        VenderCarro,
        MotivosCarro,
        FacilVender,
        FormVendaCarro
    }
}
</script>

<style>

    .flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    section.sessao-one{
        width: 100%;
        padding: 30px 0px;
        background: var(--color-black--);
        text-align: center;
    }

    section.sessao-two{
        width: 100%;
        background: var(--color-pp--);
        padding: 60px 0px;
    }

    section.sessao-three{
        width: 100%;
        padding: 60px 0px;
    }

    section.sessao-four{
        width: 100%;
        padding: 60px 0px;
        background: var(--color-white--);
    }

    @media screen and (max-width: 645px) {

        section.sessao-two{
            padding: 40px 0px;
        }
            section.sessao-three{
            width: 100%;
            padding: 40px 0px;
        }
    }

    @media screen and (max-width: 480px) {
        section.sessao-two{
            padding: 30px 0px;
        }
            section.sessao-three{
            width: 100%;
            padding: 30px 0px;
        }
    }
</style>