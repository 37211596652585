<template>
  <div class="informacao-quarta">
    <div class="flex">
        <div class="col-one">
            <h2>Seja nosso parceiro</h2>

            <p>Seja dono de uma RBA e faça parte da maior rede de franquias de
            consultoria do Brasil na modalidade loja física ou escritorio!</p>

            <div class="btn-action-scroll" @click="encaminharUrl">
                <span>SAIBA MAIS</span>
                <img src="../assets/row-bottom.svg" alt="">
            </div><!--btn-action-scroll-->
        </div><!--col-one-->

        <div class="col-two">
            <img src="../assets/img-2.png" alt="">
        </div><!--col-two-->
    </div><!--flex-->
  </div><!--informacao-quarta-->
</template>

<script>
export default {
    methods: {
        encaminharUrl: function(){
            location.href = "https://consultoria.franquiasdobrasil.com/"
        }
    }
}
</script>

<style scoped>
    div.informacao-quarta{
        width: 100%;
        height: auto;
    }

    div.informacao-quarta .flex{
        align-items: center;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
    }

    div.informacao-quarta .flex .col-one{
        width: 40%;
        height: auto;
    }

    div.informacao-quarta .flex .col-one h2{
        color: var(--color-white--);
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 40px;
        margin-bottom: 20px;
        max-width: 80%;
    }

    div.informacao-quarta .flex .col-one h2 span{
        color: var(--color-pp--);
        display: block; 
    }

    div.informacao-quarta .flex .col-one p{
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        margin-bottom: 30px;
        color: var(--color-white--);
    }

    div.informacao-quarta .flex .col-one .btn-action-scroll{
        padding: 10px 25px;
        border-radius: 5px;
        background: var(--color-pp--);
        display: inline-flex;
        align-items: center;
        transition: .5s;
        cursor: pointer;
    }

    div.informacao-quarta .flex .col-one .btn-action-scroll:hover{
        background: var(--color-pp-hover--);
    }

    div.informacao-quarta .flex .col-one .btn-action-scroll span{
        color: var(--color-white--);
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 600;
        margin-right: 10px;
    }

    div.informacao-quarta .flex .col-one .btn-action-scroll img{
        width: 20px;
        height: auto;
        object-fit: contain;
        position: relative;
        top: -1px;
    }

    div.informacao-quarta .flex .col-two{
        width: 500px;
        height: auto;
    }

    div.informacao-quarta .flex .col-two img{
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    @media screen and (max-width: 900px) {
        div.informacao-quarta .flex .col-one{
            width: 100%;
            height: auto;
            text-align: center;
            margin-bottom: 30px;
        }
        div.informacao-quarta .flex .col-one h2{
            max-width: none;
        }

        div.informacao-quarta .flex .col-two{
            width: 500px;
            height: auto;
            margin: 0 auto;
        }
    }

    @media screen and (max-width: 680px){
        div.informacao-quarta .flex .col-one h2{
            font-size: 32px;
        }
    }

    @media screen and (max-width: 500px){
        div.informacao-quarta .flex .col-one h2{
            font-size: 28px;
        }
    }

    @media screen and (max-width: 480px){
        div.informacao-quarta .flex .col-one h2{
            font-size: 22px;
        }
        div.informacao-quarta .flex .col-one p{
            font-size: 14px;
            margin-bottom: 15px;
        }

    }

</style>