<template>
  <div class="texto-pp">
    <h4>Rede <span>Franquias do Brasil</span></h4>
  </div><!--texto-pp-->
  <div class="informacao-primaria">
    <div class="col-one">
        <h1><span>Somos consultoria para consórcio contemplado</span>
        e em andamento, intermediação de <span>venda de veículos e muito mais.</span>
        Tudo em um só lugar!</h1>

        <div class="btn-action-scroll" @click="scroll">
            <span>VEJA MAIS</span>
            <img src="../assets/row-bottom.svg" alt="">
        </div><!--btn-action-scroll-->
    </div><!--col-one-->

    <div class="col-two">
        <div class="imagem">
            <img src="../assets/img-1.png" alt="">
        </div><!--imagem-->
    </div><!--col-two-->
  </div><!--informacao-primaria-->
</template>

<script>
export default {
  methods: {
    scroll(){
      this.$emit('scroll-one');
    }
  }
}
</script>

<style scoped>
  .texto-pp{
    display: inline-block;
    text-align: center;
    margin-top: 30px;
    padding: 15px;
    border: 1px solid var(--color-white--);
    border-radius: 8px;
  }

  .texto-pp h4{
    color: var(--color-white--);
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
  }
  div.informacao-primaria{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: wrap;
  }

  div.informacao-primaria .col-one{
    width: 50%;
    text-align: left;
  }

  div.informacao-primaria .col-one h1{
    color: var(--color-white--);
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 32px;
    margin-bottom: 20px;
  }

  div.informacao-primaria .col-one h1 span{
    color: var(--color-pp--);
  }

  div.informacao-primaria .col-one h3{
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    margin-bottom: 30px;
    color: var(--color-white--);
  }

  div.informacao-primaria .col-one .btn-action-scroll{
    padding: 10px 25px;
    border-radius: 5px;
    background: var(--color-pp--);
    display: inline-flex;
    align-items: center;
    transition: .5s;
    cursor: pointer;
  }

  div.informacao-primaria .col-one .btn-action-scroll:hover{
    background: var(--color-pp-hover--);
  }

  div.informacao-primaria .col-one .btn-action-scroll span{
    color: var(--color-white--);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
  }

  div.informacao-primaria .col-one .btn-action-scroll img{
    width: 20px;
    height: auto;
    object-fit: contain;
    position: relative;
    top: -1px;
  }

  div.informacao-primaria .col-two{
    width: 50%;
    height: auto;
  }

  div.informacao-primaria .col-two img{
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  @media screen and (max-width: 880px) {
      div.informacao-primaria .col-one h1{
      font-size: 26px;
    }
  }

  @media screen and (max-width: 770px){
    div.informacao-primaria .col-one{
        width: 100%;
        text-align: center;
        order: 2;
      }
    div.informacao-primaria .col-two{
        width: 100%;
        order: 1;
        height: auto;
        margin-bottom: 30px;
      }
  }

  @media screen and (max-width: 500px) {
    .texto-pp h4{
      font-size: 16px;
    }
      div.informacao-primaria .col-one h1{
      font-size: 22px;
    }
  }
</style>