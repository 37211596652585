<template>
  <div class="sobre-empresa" ref="sobreEmpresa">
    <div class="flex">
        <div class="col-one">
            <h3>Sobre a <span>RBA</span>:</h3>

            <p>Bem-vindo ao nosso site, onde a realização dos seus sonhos está a um
            passo de distância!
            <br><br>
            Somos especializados em consultoria e assessoria na modalidade de
            consórcio, além de intermediar a venda de consórcios contemplados, tanto
            em nossa plataforma quanto em parceria com outros especialistas do
            setor.
            <br><br>
            Também auxiliamos clientes que desejam vender seu veículo, conectando
            vendedores e compradores diretamente em nosso Marketplace.
            <br><br>
            Na RBA franquias, estamos aqui para oferecer todo o suporte necessário
            para que você encontre exatamente o que procura. Conte conosco</p>
        </div><!--col-one-->

        <div class="col-two">
            <h3>Sobre a <span>RBA</span>:</h3>
            <div class="circle-img">
                <img src="../assets/img-bg-4.jpg" alt="">
            </div><!--circle-img-->
        </div><!--col--two-->
    </div><!--flex-->
  </div><!--sobre-empresa-->
</template>

<script>
export default {
    methods: {
        scrollToSection(){
            this.$refs.sobreEmpresa.scrollIntoView({ behavior: 'smooth' }); 
        }
    }
}
</script>

<style scoped>
    div.sobre-empresa{
        width: 100%;
        display: inline-block;
        position: relative;
        z-index: 1;
    }

    div.sobre-empresa .flex{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    div.sobre-empresa .flex .col-one{
        width: 60%;
        text-align: left;
    }

    h3{
        color: var(--color-black--);
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 15px;
        font-family: 'Roboto', sans-serif;
    }

    div.sobre-empresa .flex .col-two h3{
        display: none;
    }

    div.sobre-empresa .flex .col-one h3 span{
        color: var(--color-pp--);
        font-weight: 800;
    }

    div.sobre-empresa .flex .col-two h3 span{
        color: var(--color-pp--);
        font-weight: 800;
    }

    div.sobre-empresa .flex .col-one p{
        color: var(--color-black--);
        font-family: "Roboto", Sans-serif;
        font-size: 16px;
        font-weight: 400;
        font-family: 'Roboto', sans-serif;
    }

    div.sobre-empresa .flex .col-two{
        width: 436px;
        height: 436px;
        border-radius: 50%;
    }

    div.sobre-empresa .flex .col-two div.circle-img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
        overflow: hidden;
    }

    div.sobre-empresa .flex .col-two div.circle-img img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media screen and (max-width:1200px) {
        div.sobre-empresa .flex .col-one{
        width: 50%;
        text-align: left;
    }
    }

    @media screen and (max-width: 970px){
        div.sobre-empresa .flex .col-one{
            width: 100%;
            order: 2;
            text-align: center;
        }

        div.sobre-empresa .flex .col-one h3{
            display: none;
            text-align: center;
        }

        div.sobre-empresa .flex .col-two{
            width: 100%;
            height: auto;
            text-align: center;
            margin-bottom: 30px;
        }

        div.sobre-empresa .flex .col-two .circle-img{
            width: 536px !important;
            height: 536px !important;
            margin: 0 auto;
        }

        div.sobre-empresa .flex .col-two h3{
            display: inline-block;
            margin-bottom: 20px;
            
        }
    }

    @media screen and (max-width: 680px){
        div.sobre-empresa .flex .col-two .circle-img{
            width: 386px !important;
            height: 386px !important;
        }

        h3{
            font-size: 26px;
        }

        div.sobre-empresa .flex .col-one p{
            font-size: 16px;
        }
    }
</style>